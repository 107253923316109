import React from 'react';
import { Redirect } from '@reach/router';
import { graphql, Link } from 'gatsby';
import { AboutBlock, CareersBlock, CaseStudyBlock, FeaturedServices, Hero } from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';

export const query = graphql`
  query MarketQuery($uid: String!) {
    prismic {
      doc: market(uid: $uid, lang: "en-us") {
        _meta {
          uid
        }
        meta_title
        meta_description
        name
        hero_copy
        hero_image
        services_header
        services_copy
        featured_service_1 {
          ... on PRISMIC_Service_area {
            _meta {
              uid
            }
            thumbnail
            name
            hero {
              ... on PRISMIC_Service_hero {
                heading
                image
                text
                text
                body {
                  ... on PRISMIC_Service_heroBodyMarket_hero {
                    primary {
                      market {
                        ... on PRISMIC_Market {
                          _meta {
                            uid
                          }
                        }
                      }
                      heading1
                      image1
                      text1
                    }
                  }
                }
              }
            }
          }
        }
        featured_service_2 {
          ... on PRISMIC_Service_area {
            _meta {
              uid
            }
            thumbnail
            name
            hero {
              ... on PRISMIC_Service_hero {
                heading
                image
                text
                body {
                  ... on PRISMIC_Service_heroBodyMarket_hero {
                    primary {
                      market {
                        ... on PRISMIC_Market {
                          _meta {
                            uid
                          }
                        }
                      }
                      heading1
                      image1
                      text1
                    }
                  }
                }
              }
            }
          }
        }
        featured_project_eyebrow_text
        featured_project {
          ... on PRISMIC_Publication {
            _meta {
              uid
            }
            title
            summary
            image
            body {
              ... on PRISMIC_PublicationBodyProject_proof_data {
                primary {
                  stat_1_value
                  stat_1_context
                  stat_1_source
                  stat_2_value
                  stat_2_context
                  stat_2_source
                  stat_3_value
                  stat_3_context
                  stat_3_source
                }
              }
            }
          }
        }
        services {
          service {
            ... on PRISMIC_Service_area {
              _meta {
                uid
              }
              name
              thumbnail
              hero {
                ... on PRISMIC_Service_hero {
                  body {
                    ... on PRISMIC_Service_heroBodyMarket_hero {
                      type
                      primary {
                        market {
                          ... on PRISMIC_Market {
                            _meta {
                              uid
                            }
                          }
                        }
                        image1
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      homepage: allHome_page__generals {
        edges {
          node {
            about_eyebrow
            about_header
            about_copy
            careers_eyebrow
            careers_header
            careers_copy
            careers_background
            careers_image
          }
        }
      }
      publications: allPublications(sortBy: meta_lastPublicationDate_DESC, first: 3) {
        edges {
          node {
            _meta {
              id
              uid
            }
            title
            type
            image
            summary
          }
        }
      }
    }
  }
`;

function featuredBody(uid, feature) {
  const generic = {
    heading: feature.hero.heading || feature.name,
    image:
      (feature.hero.image && feature.hero.image.IndustryFeaturedServicesImage) ||
      feature.thumbnail.IndustryFeaturedServicesImage,
    text: feature.hero.text,
    uid: feature._meta.uid,
  };

  return !feature.hero.body
    ? generic
    : feature.hero.body.reduce((body, next) => {
        return next.primary.market._meta.uid !== uid
          ? body
          : {
              heading: next.primary.heading1 || generic.heading,
              image:
                (next.primary.image1 && next.primary.image1.IndustryFeaturedServicesImage) ||
                generic.image,
              text: next.primary.text1 || generic.text,
              uid: feature._meta.uid,
            };
      }, generic);
}

function serviceThumbnail(uid, service) {
  const generic =
    (service.hero.image && service.hero.image.IndustryServiceAreaThumbnail) || service.thumbnail;

  return !service.hero.body
    ? generic
    : service.hero.body.reduce((image, next) => {
        return next.primary.market._meta.uid !== uid
          ? image
          : (next.primary.image1 && next.primary.image1.IndustryServiceAreaThumbnail) || generic;
      }, generic);
}

export default function({ data }) {
  // Check route
  const { doc } = data.prismic;

  if (!doc) return <Redirect to="/" noThrow />;

  // Set content
  const content = data.prismic.homepage.edges[0].node;
  const featured1 = featuredBody(doc._meta.uid, doc.featured_service_1);
  const featured2 = featuredBody(doc._meta.uid, doc.featured_service_2);

  const featuredServices = [
    {
      slug: `/services/${featured1.uid}`,
      name: featured1.heading[0].text,
      copy: featured1.text,
      thumbnail: featured1.image,
    },
    {
      slug: `/services/${featured2.uid}`,
      name: featured2.heading[0].text,
      copy: featured2.text,
      thumbnail: featured2.image,
    },
  ];

  const allServices = doc.services.map((item) => {
    const imageThumbnail = serviceThumbnail(doc._meta.uid, item.service);

    return {
      uid: item.service._meta.uid,
      name: item.service.name[0].text,
      href: `/services/${item.service._meta.uid}`,
      image: imageThumbnail,
    };
  });

  const stats = [
    {
      highlightsProps: doc.featured_project.body[0].primary.stat_1_value,
      captionProps: doc.featured_project.body[0].primary.stat_1_context,
    },
    {
      highlightsProps: doc.featured_project.body[0].primary.stat_2_value,
      captionProps: doc.featured_project.body[0].primary.stat_2_context,
    },
    {
      highlightsProps: doc.featured_project.body[0].primary.stat_3_value,
      captionProps: doc.featured_project.body[0].primary.stat_3_context,
    },
  ];

  return (
    <Layout
      title={doc.meta_title}
      description={doc.meta_description}
      image={doc.hero_image && doc.hero_image.url}
    >
      <Hero
        imageMobile={doc.hero_image && doc.hero_image.url}
        imageDesk={doc.hero_image.url}
        heroHeader={doc.name}
        heroCopy={doc.hero_copy}
        htmlSerializer={htmlSerializer}
        isMarkets
        isAbout
      />
      <FeaturedServices
        Link={Link}
        featuredServices={featuredServices}
        allServices={allServices}
        htmlSerializer={htmlSerializer}
        eyebrowProps={{
          color: 'accent-2',
          text: 'Services',
        }}
        titleProps={{
          color: 'dark-1',
          text: doc.services_header,
        }}
        bodyProps={{
          color: 'dark-1',
          text: doc.services_copy[0].text,
        }}
        ctaButtonProps={{
          to: '',
        }}
      />

      <CaseStudyBlock
        Link={Link}
        stats={stats}
        image={doc.featured_project.image}
        eyebrowProps={{
          color: 'accent-2',
          text: 'Featured Project',
        }}
        titleProps={{
          text: doc.featured_project.title,
        }}
        bodyProps={{
          text: doc.featured_project.summary,
        }}
        ctaButtonProps={{
          to: `/library/${doc.featured_project._meta.uid}`,
          text: 'View the project',
        }}
        htmlSerializer={htmlSerializer}
      />
      <AboutBlock
        Link={Link}
        eyebrowProps={{
          color: 'white',
          text: content.about_eyebrow,
        }}
        titleProps={{
          color: 'white',
          text: content.about_header,
        }}
        bodyProps={{
          color: 'white',
          text: content.about_copy[0].text,
        }}
        ctaButtonProps={{
          color: 'white',
          to: '/about',
          text: 'About Power',
        }}
      />
      <CareersBlock
        Link={Link}
        bkgImage={content.careers_background.url}
        image={content.careers_image}
        eyebrowProps={{
          color: 'accent-2',
          text: content.careers_eyebrow,
        }}
        titleProps={{
          color: 'dark-1',
          text: content.careers_header,
        }}
        bodyProps={{
          color: 'dark-2',
          text: content.careers_copy[0].text,
        }}
        ctaButtonProps={{
          to: '/careers',
          text: 'Power Careers',
        }}
      />
    </Layout>
  );
}
